var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"container-buttons"},[(_vm.allows_crud && !_vm.rubric)?_c('b-button',{staticClass:"mb-2 mt-4 ml-2",attrs:{"size":"sm","variant":"primary"},on:{"click":function($event){return _vm.$bvModal.show(
          `modal-create-rubric-${_vm.instrument_id}-${_vm.container_instrument_id}`
        )}}},[_vm._v("+ Agregar "+_vm._s(_vm.$getVisibleNames("evaluations2.rubric", false, "Pauta")))]):_vm._e()],1),(_vm.rubric && !_vm.evaluatee_view && !_vm.evaluator_view && !_vm.preview_evaluation)?_c('div',{staticClass:"d-flex justify-content-left mb-2 mr-2 noprint"},[_c('b-button',{staticClass:"noprint",attrs:{"variant":"primary","size":"sm"},on:{"click":function($event){return _vm.$bvModal.show(
          `modal-specification-table-rubric-${_vm.test.id}-${_vm.container_instrument_id}-${_vm.rubric.id}`
        )}}},[_c('div',{staticClass:"d-flex align-items-center"},[_c('b-icon',{staticClass:"mr-1",attrs:{"icon":"card-list"}}),_c('span',[_vm._v("Tabla de Especificaciones")])],1)]),_c('b-modal',{attrs:{"id":`modal-specification-table-rubric-${_vm.test.id}-${_vm.container_instrument_id}-${_vm.rubric.id}`,"title":`Tabla de Especificaciones`,"hide-footer":"","size":"xl"}},[_c('NewSpecificationTable',{attrs:{"allows_crud":_vm.allows_crud,"test":_vm.test,"observation_table":true,"rubric_id":_vm.rubric.id,"evaluation_criteria_micro_ids":_vm.evaluation_criteria_micro_ids}})],1)],1):_vm._e(),(_vm.rubric && _vm.filter_rubric_instance == null)?_c('HeaderRubric',{attrs:{"container_instrument_id":_vm.container_instrument_id,"instrument_id":_vm.instrument_id,"rubric_id":_vm.rubric.id,"allows_crud":_vm.allows_crud},on:{"reset_rubric_coevaluators":() => _vm.key_coevaluator_rubric++}}):_vm._e(),(_vm.rubric && _vm.filter_rubric_instance == null)?_c('b-table',{attrs:{"fields":_vm.fields,"items":_vm.rubricAchievementList,"small":"","caption-top":"","bordered":"","show-empty":"","empty-text":"No hay niveles de logro para mostrar."},scopedSlots:_vm._u([{key:"cell(description)",fn:function(row){return [_c('div',{staticClass:"rich-text-content",domProps:{"innerHTML":_vm._s(row.item.description)}})]}}],null,false,3627676419)}):_vm._e(),(
      _vm.test &&
      _vm.test.evaluatees.length > 1 &&
      _vm.rubric &&
      _vm.evaluative_agent &&
      (['duoc_coevaluacion_evaluative_agent'].includes(
        _vm.evaluative_agent.internal_use_id
      ) ||
        _vm.$equals(_vm.evaluative_agent.name, 'Coevaluación'))
    )?_c('CoEvaluatorRubric',{key:_vm.key_coevaluator_rubric,attrs:{"rubric":_vm.rubric,"test":_vm.test,"user_id":_vm.user_id,"allows_crud":_vm.allows_crud,"evaluatee_view":_vm.evaluatee_view,"is_running_test":_vm.is_running_test,"finished_test":_vm.finished_test,"evaluator_view":_vm.evaluator_view}}):_vm._e(),(
      _vm.rubric &&
      ((((_vm.is_running_test && !_vm.finished_test) ||
        (!_vm.is_running_test && _vm.finished_test)) &&
        _vm.evaluatee_view) ||
        _vm.evaluator_view)
    )?_c('SelectedCoevaluatorRubric',{attrs:{"rubric":_vm.rubric,"test":_vm.test,"user_id":_vm.user_id,"allows_crud":_vm.allows_crud,"evaluatee_view":_vm.evaluatee_view,"is_running_test":_vm.is_running_test,"finished_test":_vm.finished_test,"evaluator_view":_vm.evaluator_view},on:{"student_selcted":_vm.slotStudentSelected}}):_vm._e(),_c('div',{staticClass:"table-rubric"},[(_vm.rubric)?_c('table',[_c('thead',[_c('tr',[_c('th',{attrs:{"colspan":_vm.evaluation &&
              (_vm.evaluation.matter_evaluation != null ||
                _vm.evaluation.egress_profile_matter_evaluation != null ||
                (_vm.evaluation.parent_evaluation != null &&
                  _vm.evaluation_criteria_micro_ids.length > 0)) &&
              _vm.institution &&
              _vm.institution.internal_use_id != 'duoc_uc'
                ? 3
                : 2}}),_c('th',{attrs:{"colspan":_vm.rubricAchievementList.length > 0
                ? _vm.rubricAchievementList.length
                : 1}},[_vm._v(" "+_vm._s(_vm.$getVisibleNames( "evaluations.observationachievement", true, "Niveles de Logro" ).toUpperCase())+" "),(_vm.allows_crud && !_vm.rubric.is_locked)?_c('button-add',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.top.noninteractive",value:(
                `Crear ${_vm.$getVisibleNames(
                  'evaluations.observationachievement',
                  false,
                  'Niveles de Logro'
                )}`
              ),expression:"\n                `Crear ${$getVisibleNames(\n                  'evaluations.observationachievement',\n                  false,\n                  'Niveles de Logro'\n                )}`\n              ",modifiers:{"v-secondary":true,"top":true,"noninteractive":true}}],staticClass:"add-button",attrs:{"title":`Crear ${_vm.$getVisibleNames(
                'evaluations.observationachievement',
                false,
                'Niveles de Logro'
              ).toUpperCase()}`,"size":"sm"},on:{"click":function($event){return _vm.$bvModal.show(
                  `modal-create-rubric-achievement-${_vm.instrument_id}-${_vm.container_instrument_id}`
                )}}}):_vm._e()],1),_c('th',{attrs:{"colspan":!_vm.rubric.is_locked ? 2 : 1}})]),_c('tr',[_c('th',{staticStyle:{"width":"1%"}},[_vm._v("N°")]),_c('th',{staticClass:"dimension-column",staticStyle:{"width":"20%"}},[_vm._v(" "+_vm._s(_vm.$getVisibleNames( "manual.dimension", true, "Dimensión" ).toUpperCase())+" "),(_vm.allows_crud && !_vm.rubric.is_locked)?_c('button-add',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.top.noninteractive",value:(
                `Crear ${_vm.$getVisibleNames(
                  'manual.dimension',
                  false,
                  'Dimensión'
                )}`
              ),expression:"\n                `Crear ${$getVisibleNames(\n                  'manual.dimension',\n                  false,\n                  'Dimensión'\n                )}`\n              ",modifiers:{"v-secondary":true,"top":true,"noninteractive":true}}],staticClass:"add-button",attrs:{"title":`Crear ${_vm.$getVisibleNames(
                'manual.dimension',
                false,
                'Dimensión'
              )}`,"size":"sm"},on:{"click":function($event){return _vm.$bvModal.show(
                  `modal-created-rubric-criteria-${_vm.instrument_id}-${_vm.container_instrument_id}`
                )}}}):_vm._e()],1),(
              _vm.evaluation &&
              (_vm.evaluation.matter_evaluation != null ||
                _vm.evaluation.egress_profile_matter_evaluation != null ||
                (_vm.evaluation.parent_evaluation != null &&
                  _vm.evaluation_criteria_micro_ids.length > 0)) &&
              _vm.institution &&
              _vm.institution.internal_use_id != 'duoc_uc'
            )?_c('th',{staticStyle:{"width":"15%"}},[_vm._v(" "+_vm._s(_vm.$getVisibleNames( "teaching.evaluationcriteriamicro", false, "Criterio de Evaluación Micro" ).toUpperCase())+" ")]):_vm._e(),_vm._l((_vm.rubricAchievementList),function(rubric_achievement){return _c('th',{key:rubric_achievement.id,staticStyle:{"width":"15%"}},[_vm._v(" "+_vm._s(rubric_achievement.title.toUpperCase())+" "),_c('br'),_vm._v(" "+_vm._s(rubric_achievement.score)+" "),(_vm.rubric.show_percentage_sign)?[_vm._v("%")]:[_vm._v(" PUNTOS")],(_vm.allows_crud && !_vm.rubric.is_locked)?_c('button-edit',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.top.noninteractive.v-secondary",value:(
                `Editar ${_vm.$getVisibleNames(
                  'evaluations.observationachievement',
                  false,
                  'Niveles de Logro'
                )}`
              ),expression:"\n                `Editar ${$getVisibleNames(\n                  'evaluations.observationachievement',\n                  false,\n                  'Niveles de Logro'\n                )}`\n              ",modifiers:{"top":true,"noninteractive":true,"v-secondary":true}}],staticClass:"add-button",on:{"click":function($event){return _vm.$bvModal.show(
                  `modal-update-rubric-achievement-${_vm.instrument_id}-${rubric_achievement.id}-${_vm.container_instrument_id}`
                )}}}):_vm._e(),(_vm.allows_crud && !_vm.rubric.is_locked)?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.top.noninteractive.v-secondary",value:(
                `Eliminar ${_vm.$getVisibleNames(
                  'evaluations.observationachievement',
                  false,
                  'Niveles de Logro'
                )}`
              ),expression:"\n                `Eliminar ${$getVisibleNames(\n                  'evaluations.observationachievement',\n                  false,\n                  'Niveles de Logro'\n                )}`\n              ",modifiers:{"top":true,"noninteractive":true,"v-secondary":true}}],staticClass:"action-button-delete delete-btn",attrs:{"variant":"none"},on:{"click":function($event){return _vm.deleteRubricAchievement(rubric_achievement.id)}}},[_c('b-icon-trash',{attrs:{"font-scale":"1"}})],1):_vm._e(),_c('b-modal',{attrs:{"id":`modal-update-rubric-achievement-${_vm.instrument_id}-${rubric_achievement.id}-${_vm.container_instrument_id}`,"title":`Editar ${_vm.$getVisibleNames(
                'evaluations.observationachievement',
                false,
                'Niveles de Logro'
              )}`,"size":"lg","hide-footer":""}},[_c('RubricAchievementForm',{attrs:{"rubric_id":_vm.rubric.id,"RubricAchievement":rubric_achievement,"order":rubric_achievement.order,"show_delete_button":true},on:{"updated":function($event){return _vm.$bvModal.hide(
                    `modal-update-rubric-achievement-${_vm.instrument_id}-${rubric_achievement.id}-${_vm.container_instrument_id}`
                  )},"deleted":function($event){return _vm.$bvModal.hide(
                    `modal-update-rubric-achievement-${_vm.instrument_id}-${rubric_achievement.id}-${_vm.container_instrument_id}`
                  )}}})],1)],2)}),(_vm.rubricAchievementList.length == 0)?_c('th',[_vm._v(" No tiene "+_vm._s(_vm.$getVisibleNames( "evaluations.observationachievement", true, "Niveles de Logro" ))+" ")]):_vm._e(),_c('th',{staticStyle:{"width":"3%"}},[_vm._v("PONDERACIÓN")]),(_vm.allows_crud && !_vm.rubric.is_locked)?_c('th',{staticStyle:{"width":"1%"}}):_vm._e()],2)]),_c('draggable',{attrs:{"disabled":!_vm.allows_crud || _vm.rubric.is_locked,"tag":"tbody","handle":".handle"},model:{value:(_vm.rubricInstanceList),callback:function ($$v) {_vm.rubricInstanceList=$$v},expression:"rubricInstanceList"}},[_vm._l((_vm.rubricInstanceList),function(rubric_criteria){return [_c('tr',{key:`${rubric_criteria.id}`},[_c('td',{staticClass:"handle"},[(_vm.allows_crud && !_vm.rubric.is_locked)?_c('b-icon',{staticClass:"handle-icon",attrs:{"icon":"arrows-expand","scale":"1.2"}}):[_vm._v(" "+_vm._s(rubric_criteria.order)+" ")]],2),_c('td',{staticClass:"td-rubric-descriptor-cell"},[_c('div',{staticClass:"pt-1 sentence-cell"},[_c('SentenceContainer',{staticClass:"ml-1",class:{
                    'unselectable-text':
                      (_vm.user && _vm.user.groups.includes(7)) || _vm.preview_evaluation,
                  },attrs:{"Sentence":rubric_criteria,"showColorCognitiveLevel":_vm.allows_crud,"showCognitiveLevel":!_vm.preview_evaluation,"boundedTextContainer":(_vm.evaluator_view ||
                      _vm.evaluatee_view ||
                      _vm.preview_evaluation) &&
                    _vm.rubric &&
                    !_vm.rubric.allow_criteria_performance_level_input
                      ? true
                      : false,"single_line":(_vm.evaluator_view ||
                      _vm.evaluatee_view ||
                      _vm.preview_evaluation) &&
                    _vm.rubric &&
                    !_vm.rubric.allow_criteria_performance_level_input
                      ? true
                      : false}})],1)]),(
                _vm.evaluation &&
                (_vm.evaluation.matter_evaluation != null ||
                  _vm.evaluation.egress_profile_matter_evaluation != null ||
                  (_vm.evaluation.parent_evaluation != null &&
                    _vm.evaluation_criteria_micro_ids.length > 0)) &&
                _vm.institution &&
                _vm.institution.internal_use_id != 'duoc_uc'
              )?_c('td',{staticClass:"td-rubric-descriptor-cell"},[(rubric_criteria.evaluation_criteria_micro != null)?_c('div',{staticClass:"pt-1 sentence-cell container-sentence"},[_c('span',{staticClass:"ml-1"},[_vm._v(" "+_vm._s(_vm.orderEvaluationCriteriaMicro( rubric_criteria.evaluation_criteria_micro ))+" ")]),_c('SentenceContainer',{staticClass:"ml-1 text-cell-sencente",class:{
                    'unselectable-text':
                      (_vm.user && _vm.user.groups.includes(7)) || _vm.preview_evaluation,
                  },attrs:{"Sentence":_vm.evaluation_criteria_micros.find(
                      (x) => x.id == rubric_criteria.evaluation_criteria_micro
                    ),"showColorCognitiveLevel":_vm.allows_crud,"showCognitiveLevel":!_vm.preview_evaluation,"boundedTextContainer":false}})],1):_vm._e()]):_vm._e(),_vm._l((_vm.rubricAchievementList),function(rubric_achievement){return _c('td',{key:rubric_achievement.id,staticClass:"td-rubric-descriptor-cell",class:{
                'vertical-align-cell':
                  _vm.rubric && !_vm.rubric.allow_criteria_performance_level_input,
              }},[_c('RubricDescriptorCell',{key:rubric_criteria.id +
                  '-' +
                  rubric_achievement.id +
                  '-' +
                  _vm.key_rubric_descriptor_cell,class:{
                  'unselectable-text':
                    (_vm.user && _vm.user.groups.includes(7)) || _vm.preview_evaluation,
                },attrs:{"container_instrument_id":_vm.container_instrument_id,"rubric":_vm.rubric,"rubric_criteria_id":rubric_criteria.id,"rubric_achievement_id":rubric_achievement.id,"allows_crud":_vm.allows_crud && !_vm.rubric.is_locked,"evaluator_view":_vm.evaluator_view,"evaluatee_view":_vm.evaluatee_view,"is_running_test":_vm.is_running_test,"finished_test":_vm.finished_test,"is_published":_vm.is_published,"preview_evaluation":_vm.preview_evaluation,"user_id":_vm.currentUserId,"coevaluator_id":_vm.currentCoevaluatorId,"closedEvaluationScore":_vm.closedEvaluationScore},on:{"created_score":_vm.changeEvaluateeTest,"updated_score":_vm.changeEvaluateeTest,"resetRubricDescriptorCell":() => _vm.key_rubric_descriptor_cell++}})],1)}),_c('td',[_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.top.danger.v-danger",value:(
                  `${
                    rubric_criteria.weighing == 0
                      ? `Este ${_vm.$getVisibleNames(
                          'manual.dimension',
                          false,
                          'Dimensión'
                        )} no pondera`
                      : ''
                  }`
                ),expression:"\n                  `${\n                    rubric_criteria.weighing == 0\n                      ? `Este ${$getVisibleNames(\n                          'manual.dimension',\n                          false,\n                          'Dimensión'\n                        )} no pondera`\n                      : ''\n                  }`\n                ",modifiers:{"top":true,"danger":true,"v-danger":true}}],class:{ 'weighing-red': rubric_criteria.weighing == 0 }},[_vm._v(" "+_vm._s(rubric_criteria.weighing)+" % ")])]),(_vm.rubricAchievementList.length == 0)?_c('td'):_vm._e(),(_vm.allows_crud && !_vm.rubric.is_locked)?_c('td',[_c('div',{staticClass:"container-buttons-dimension"},[_c('button-edit',{staticClass:"icon-edit",on:{"click":function($event){return _vm.$bvModal.show(
                      `modal-update-rubric-instance-${_vm.instrument_id}-${rubric_criteria.id}-${_vm.container_instrument_id}`
                    )}}}),(_vm.allows_crud)?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.top.noninteractive.v-secondary",value:(
                    `Eliminar la ${_vm.$getVisibleNames(
                      'manual.dimension',
                      false,
                      'Dimensión'
                    )}`
                  ),expression:"\n                    `Eliminar la ${$getVisibleNames(\n                      'manual.dimension',\n                      false,\n                      'Dimensión'\n                    )}`\n                  ",modifiers:{"top":true,"noninteractive":true,"v-secondary":true}}],staticClass:"action-button-delete-dimension delete-btn",attrs:{"variant":"none"},on:{"click":function($event){return _vm.deleteRubricCriteria(rubric_criteria.id)}}},[_c('b-icon-trash',{staticClass:"icon-trash",attrs:{"font-scale":"1"}})],1):_vm._e()],1),_c('b-modal',{attrs:{"id":`modal-update-rubric-instance-${_vm.instrument_id}-${rubric_criteria.id}-${_vm.container_instrument_id}`,"title":'Modificar ' +
                  _vm.$getVisibleNames('manual.dimension', false, 'Dimensión'),"hide-footer":"","size":"xl"}},[(_vm.rubric)?_c('RubricCriteriaForm',{attrs:{"rubric_id":_vm.rubric.id,"RubricCriteria":rubric_criteria,"order":rubric_criteria.order,"evaluation_criteria_micro_ids":_vm.evaluation_criteria_micro_ids,"show_delete_button":true},on:{"updated":function($event){return _vm.$bvModal.hide(
                      `modal-update-rubric-instance-${_vm.instrument_id}-${rubric_criteria.id}-${_vm.container_instrument_id}`
                    )},"deleted":function($event){return _vm.$bvModal.hide(
                      `modal-update-rubric-instance-${_vm.instrument_id}-${rubric_criteria.id}-${_vm.container_instrument_id}`
                    )}}}):_vm._e()],1)],1):_vm._e()],2)]})],2),(_vm.rubricInstanceList.length == 0)?[_c('tbody',[_c('b-tr',[_c('b-td',{attrs:{"colspan":_vm.allows_crud
                  ? 5 + _vm.rubricAchievementList.length
                  : 4 + _vm.rubricAchievementList.length}},[_c('strong',[_vm._v(" No hay "+_vm._s(_vm.$getVisibleNames("manual.dimension", true, "Dimensiones"))+" creadas ")])])],1)],1)]:_vm._e(),(_vm.filter_rubric_instance == null)?_c('tfoot',[_c('tr',[_c('td',{attrs:{"colspan":_vm.evaluation &&
              (_vm.evaluation.matter_evaluation != null ||
                _vm.evaluation.egress_profile_matter_evaluation != null ||
                (_vm.evaluation.parent_evaluation != null &&
                  _vm.evaluation_criteria_micro_ids.length > 0)) &&
              _vm.institution &&
              _vm.institution.internal_use_id != 'duoc_uc'
                ? 3
                : 2}}),_vm._l((_vm.rubricAchievementList),function(rubric_achievement,index){return _c('td',{key:rubric_achievement.id},[(_vm.rubricAchievementList.length == index + 1)?[_c('strong',[_vm._v("Total")])]:_vm._e()],2)}),(_vm.rubricAchievementList.length == 0)?_c('td',[_c('strong',[_vm._v("Total")])]):_vm._e(),_c('td',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",modifiers:{"v-secondary":true,"noninteractive":true}}],style:({ color: _vm.totalWeighing != 100 ? 'red' : '' }),attrs:{"title":_vm.totalWeighing != 100
                ? 'La ponderación total debe ser igual a 100.'
                : ''}},[_c('strong',[_vm._v(_vm._s(_vm.totalWeighing)+"%")])]),(_vm.allows_crud && !_vm.rubric.is_locked)?_c('td'):_vm._e()],2)]):_vm._e()],2):_vm._e()]),_c('b-modal',{attrs:{"id":`modal-created-rubric-criteria-${_vm.instrument_id}-${_vm.container_instrument_id}`,"title":`Crear ${_vm.$getVisibleNames(
      'manual.dimension',
      false,
      'Dimensión'
    )}`,"size":"xl","hide-footer":""}},[(_vm.rubric)?_c('RubricCriteriaForm',{attrs:{"rubric_id":_vm.rubric.id,"evaluation_criteria_micro_ids":_vm.evaluation_criteria_micro_ids,"order":_vm.rubricInstanceList.length > 0
          ? _vm.rubricInstanceList[_vm.rubricInstanceList.length - 1].order + 1
          : 1},on:{"created":function($event){return _vm.$bvModal.hide(
          `modal-created-rubric-criteria-${_vm.instrument_id}-${_vm.container_instrument_id}`
        )}}}):_vm._e()],1),_c('b-modal',{attrs:{"id":`modal-create-rubric-achievement-${_vm.instrument_id}-${_vm.container_instrument_id}`,"title":`Crear el ${_vm.$getVisibleNames(
      'evaluations.observationachievement',
      false,
      'Niveles de Logro'
    )}`,"size":"lg","hide-footer":""}},[(_vm.rubric)?_c('RubricAchievementForm',{attrs:{"rubric_id":_vm.rubric.id,"order":_vm.rubricAchievementList.length > 0
          ? _vm.rubricAchievementList[_vm.rubricAchievementList.length - 1].order + 1
          : 1},on:{"created":function($event){return _vm.$bvModal.hide(
          `modal-create-rubric-achievement-${_vm.instrument_id}-${_vm.container_instrument_id}`
        )}}}):_vm._e()],1),_c('b-modal',{attrs:{"id":`modal-create-rubric-${_vm.instrument_id}-${_vm.container_instrument_id}`,"title":`Crear ${_vm.$getVisibleNames(
      'evaluations2.rubric',
      false,
      'Pauta'
    )}`,"size":"lg","hide-footer":""}},[_c('NewRubricForm',{attrs:{"test_id":_vm.instrument_id},on:{"closeModal":function($event){return _vm.$bvModal.hide(
          `modal-create-rubric-${_vm.instrument_id}-${_vm.container_instrument_id}`
        )}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }